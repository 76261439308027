<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-row>
        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Dashboard Trend Production</h6>
                    <div id="container1" style="height: 640px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {trendProduction} from "../../../backend-api/sr/dashboard/trend_production"
import _ from "lodash"

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Dashboar',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Trend Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            datas:{},
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getDashboard()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix
        },
        toggleDataSeries(e) {
            console.log(e)
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        getChart(datas, container){
            var self = this;

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Ton",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                    // logarithmic: true,
			        // logarithmBase: 2
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "center",
                    verticalAlign: "bottom",
                },
                data: datas
            });
            // console.log('option chart')
            // console.log(chart.options.data)
            // chart.options.data[0].dataPoints = dt1;
            // chart.options.data[1].dataPoints = dt2;
            // chart.options.data[2].dataPoints = dt3;
            // console.log("chart option", chart.options)

            // if (this.x.matches) {

            //     for(var i = 0; i < chart.options.data.length; i++){
            //         chart.options.data[i].indexLabelFontSize = 8;
            //     }
            //     chart.render();
            // }
            chart.render();
        },

        async getDashboard(event){
            const respData = await trendProduction.fetchDashboard('', null, false, false, false)
            if (respData.status === 200) {
                let resp = respData.data.data
                for (let key in resp) {
                    resp[key] = resp[key].map(item => {
                        return {
                            ...item,
                            y: parseFloat(item.y)
                        };
                    });
                }
                const now = new Date();

                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');

                const formattedDate = `${year}${month}`;
                resp = this.fillMissingLabelsByYear(resp, formattedDate, month)

                resp = Object.keys(resp).sort().reduce((result, key) => {
                    result[key] = resp[key];
                    return result;
                }, {});

                var data =[];
                for (let key in resp) {
                    data.push({
                        type: 'line',
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        // indexLabelFontColor: "#FFF",
                        name: key,
                        showInLegend: true,
                        visible: false,
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "horizontal",
                        yValueFormatString: "#,###.##",
                        dataPoints: resp[key]
                    })
                }
                // console.log(data)
                this.getChart(data, container1)
            }
        },
        generateLabels(startYear, startMonth, monthsBack) {
            // Initialize variables
            const labels = [];
            let currentYear = startYear;
            let currentMonth = startMonth;

            for (let i = 0; i < monthsBack; i++) {
                const label = `${currentYear}${currentMonth.toString().padStart(2, '0')}`;
                labels.push(label);

                currentMonth--;
                if (currentMonth === 0) {
                    currentMonth = 12;
                    currentYear--;
                }
            }
            return labels;
        },
        fillMissingLabelsByYear(data, currentYear, currentMonth) {
            _.forEach(data, (dataset, key) => {
                // Extract year from the dataset key
                const datasetYear = parseInt(key.split(" ")[1], 10);

                // Generate labels for the past 12 months
                const labels = this.generateLabels(datasetYear, currentMonth, 12);
                
                // Get existing labels in the dataset
                const existingLabels = _.map(dataset, 'label');
                
                // Determine which labels are missing
                const missingLabels = _.difference(labels, existingLabels);

                // Add missing labels to the dataset with a value of null
                _.forEach(missingLabels, (label) => {
                    dataset.push({ label: label, y: 0 });
                });

                // Sort dataset by label to maintain chronological order
                dataset.sort((a, b) => a.label.localeCompare(b.label));
            });

            return data;
        }
    },
}
</script>

<style scoped>
    .tr_datatable{
    background-color: #F5F7F8 !important;
    }
</style>
