import axios from 'axios';
import store from '../../../store/index'
import {backendApi} from "../../backend-api-sr"

export const trendProduction = (() =>{
    const fetchDashboard = ( async (subPath) => {
        var url = '/api/v3/sr/dashboard/production-dashboard'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    return {fetchDashboard};
})()